import "firebase/firestore"
import React from "react"
import {Tween} from "react-gsap"
import {Controller, Scene} from "react-scrollmagic"
import styled from "styled-components"
import Boundary from "../components/Boundary"
import Gutter from "../components/Gutter"
import {Heading} from "../components/Heading"
import Page from "../components/Page"
import Spacer from "../components/Spacer"
import {device} from "../helpers/mediaQueries"
import mosque2JPEG from "../images/mosque2.jpeg"

const scrollMagicHeight = 200

const AboutPage = () => {
  return (
    <Page>
      <CoverDiv>
        <Controller>
          <Scene duration="100%">
            <Tween
              ease="linear"
              from={{transform: "translateY(0)"}}
              to={{transform: `translateY(${scrollMagicHeight * 2}px)`}}
            >
              <CoverImageOuterDiv>
                <CoverImageInnerDiv src={mosque2JPEG} />
              </CoverImageOuterDiv>
            </Tween>
          </Scene>
        </Controller>
      </CoverDiv>
      <Spacer isDesktop={false} />
      <Boundary style={{textAlign: "left"}}>
        <Gutter isDesktop={false}>
          <Gutter leftPadding={false} rightPadding={false}>
            <Heading type="1">Our history</Heading>
            <p>
              Like many of the mosques and Islamic schools in the United States,
              the history of Assalam Center has humble beginnings. Founded by a
              handful of South Florida families in the mid 90s, our members had
              hopes of establishing a cultural and religious organization where
              Muslim-American children could learn Arabic and Islam with their
              peers.
            </p>
            <p>
              The early years of Assalam Center took place in the homes of
              families who were generous enough to share their space as a place
              of learning and worship. Every Sunday, teachers and students would
              meet at a different family home. Over time, we established a
              curriculum, bought textbooks, trained teachers, and enrolled new
              students, until even the biggest homes in our community could no
              longer fit all of our members.
            </p>
            <p>
              By the 2000s, Assalam Center began working with Florida Atlantic
              University, which was kind enough to allow us to rent classrooms
              on Sundays. By then, our school was teaching classes in several
              grades, with students ranging from elementary school age to high
              school.
            </p>
            <p>
              As our membership steadily grew, we began to host fundraisers to
              save enough money for our dream project - our own building in the
              heart of Boca Raton, Florida. After years of events, and thanks in
              large part to the love and generosity of our early members, we
              have been blessed with the Assalam Center School of Boca Raton.
            </p>
            <p>
              It is also thanks to our existing members that we have been able
              to keep our dream alive and thriving. If you have not yet visited
              our center, we encourage you to make the trip, and we very much
              look forward to meeting you and your family!
            </p>
          </Gutter>
        </Gutter>
      </Boundary>
    </Page>
  )
}

const CoverDiv = styled.div`
  background-color: #111;
  height: 450px;
  overflow-y: hidden;
  position: relative;
`

const CoverImageInnerDiv = styled.div`
  background: url(${(props) => props.src}) 69% bottom no-repeat;
  background-size: cover;
  bottom: 0;
  height: calc(100% + ${scrollMagicHeight}px);
  left: 0;
  position: absolute;
  right: 0;
  @media ${device.desktop} {
    background-position: center bottom;
  }
`

const CoverImageOuterDiv = styled.div`
  bottom: 0;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
`

export default AboutPage
